import crypto from "../shared/aion_crypto";
import apiClient from "./apiClient";
import {VoterChannelSubscription} from "@/channels/VoterChannel";
import {ref} from "vue";

const defaultState = () => {
  return {
    privateKey: null,
    publicKey: null,
    electionCodes: null,
    signature: null,
    voterSessionUuid: null,
    redoVote: false,
    expireReason: null,
    currentVoter: {
      id: null,
      weight: 0,
      votedOn: [],
      canVoteOn: [],
      name: "",
      voterGroupReference: null
    },
    encryptionConfig: {
      type: 'unencrypted',
      publicKey: null
    },
    e2e: {
      contests: []
    },
  }
}
const votingModule = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    startSession(state, { voterSessionUuid, privateKey, encryptionConfig, electionCodes }){
      state.voterSessionUuid = voterSessionUuid
      state.privateKey = privateKey
      state.publicKey = crypto.generateKeyPair(privateKey).public_key
      state.signature = crypto.generateSchnorrSignature('', privateKey)
      state.encryptionConfig = encryptionConfig
      state.electionCodes = electionCodes
    },
    destroySession(state, expireReason = null){
      voterChannelSubscription.value?.unsubscribe();
      Object.assign(state, defaultState(), { expireReason: expireReason })
    },
    setRedoVote(state, value){
      state.redoVote = value
    },
    setVoted(state, votingRoundReference) {
      if(state.currentVoter.votedOn.includes(votingRoundReference)) return;
      state.currentVoter.votedOn.push(votingRoundReference)
    },
    updateVoter(state, voter) {
      state.currentVoter = Object.assign(state.currentVoter, voter)
    },
    resetExpireReason(state){
      state.expireReason = null
    },
    setCastBallot(state, castBallot){
      let castState = state.e2e.contests.find(b => b.reference === castBallot.reference)
      if(castState){
        castState = Object.assign(castState, castBallot)
      } else {
        state.e2e.contests.push(castBallot)
      }
    },
  },
  actions: {
    async authenticate({state, commit, rootState, dispatch}, electionCodes){
      let privateKey = electionCodes.map(code => crypto.electionCodeToPrivateKey(code, "pbkdf2")).reduce(crypto.addBigNums)
      let signature = crypto.generateSchnorrSignature('', privateKey)
      let publicKey = crypto.generateKeyPair(privateKey).public_key

      try {
        const res = await apiClient.post(
          `${rootState.electionUrl}/authenticate`,
          {
            public_key: publicKey,
            signature: signature
          });
        let { voter_session_uuid: voterSessionUuid, encryption_config: encryptionConfig, voter } = res.data
        if( voterSessionUuid ){
          commit('startSession', {
            voterSessionUuid,
            privateKey,
            encryptionConfig,
            electionCodes,
          })
          commit('updateVoter', voter)
          //dispatch('updateStatus')
        }
      } catch (error) {
        if(error.response) {
          return error.response.data.error;
        }
      }
    },
    subscribeToVoterChannel({state, commit}) {
      voterChannelSubscription.value = new VoterChannelSubscription(state.voterSessionUuid, {
        received: function(data) {
          switch (data.type) {
            case 'update_voter':
              commit("updateVoter", data.voter);
              break;
            case 'session_expired':
              data.expire_reason ? commit("destroySession", data.expire_reason) : commit("destroySession");
              break;
          }
        },
      })
    },
    destroySession({commit}, expireReason = null){
      commit('setVisibleTab', 'Presentation', { root: true })
      commit('destroySession', expireReason)
      commit('setPosts', [], { root: true })
      commit('setSlides', [], { root: true })
    },
    prolongSession({state, rootState}) {
      apiClient.put(`${rootState.electionUrl}/prolong`, {
        signature: state.signature,
        voter_session_uuid: state.voterSessionUuid
      })
    },
    async fetchVotedOn({state, rootState, commit}) {
      const res = await apiClient.get(`${rootState.election.boardUrl}/statistics/votes/voted_on?voter_identifier=${state.currentVoter.identifier}`)
      if(res.status === 200) {
        res.data.voting_round_references.forEach(votingRoundReference => {
          commit("setVoted", votingRoundReference);
        });
      }
    },

    async reportVoted({commit, state, rootState}, ballotId) {
      let config = {
          ballotId: ballotId,
          signature: state.signature,
          voter_session_uuid: state.voterSessionUuid
      };
      return apiClient.post(`${rootState.electionUrl}/report_voted`, config)
    },
  }
}

// This feels real janky, but the subscription can't be in the actual store's state, because it updates itself
export const voterChannelSubscription = ref(null);

export default votingModule;
